import React, {FC} from 'react'
import styled from 'styled-components'

interface Props {
}

const StyledDummyPropertyWarning = styled.div`
position: absolute;
left: 0;
top: 0;
background: white;
color: black;
width: 100%;
font-weight: bold;
padding: 10px;
border-radius: 3px;
opacity: 0.6;
text-align: center;
`

const DummyPropertyWarning: FC<Props> = () => (
	<StyledDummyPropertyWarning>
		This sample home is to get you familiar with Fairmarket.
	</StyledDummyPropertyWarning>
)

export default DummyPropertyWarning;