import { MutationHookOptions, QueryHookOptions } from '@apollo/react-hooks'
import { PropertySpec, SpecReference } from '../apollo/generated'
import { BidStatus, ChargeStatus, Currency, ChargeTrigger, PropertyStatus, UserRole } from './generated'

import type {
	BidChat,
	Bid,
	Charge,
	Contractor,
	ContractorJob as IContractorJob,
	County,
	HiredProperty,
	Homeowner,
	Job as IJob,
	State,
	Trade,
} from './generated'

export { BidStatus, ChargeStatus, Currency, ChargeTrigger, PropertyStatus, UserRole }
export type {
	BidChat,
	Bid,
	Charge,
	Contractor,
	County,
	HiredProperty,
	Homeowner,
	State,
	Trade,
}

interface Entity { id: string, createdAt?: Date, updatedAt?: Date }

export interface IRenworksViewer { } // eslint-ignore-line

export interface IRenoworksSpec {
	materialRef: string
	name: string
	index?: number
	material: string
	tags: string[]
}
type RenoworksColorT = [number, number, number]

export interface IRenoworksOpts {
	env?: string
	onPick?: () => void
	hover?: boolean
	groundSelect?: boolean
	selectionFilter?: () => boolean
	selectionColor?: [number, number, number, number]
	defaultColors?: {
		Roofing?: RenoworksColorT
		Siding?: RenoworksColorT
		Trim?: RenoworksColorT
		MasonryBand?: RenoworksColorT
	}
}

export interface IRenoworks {
	init: (canvas: HTMLCanvasElement, opts?: IRenoworksOpts) => IRenworksViewer
	display: (viewer: IRenworksViewer, renoworks_id: string) => void
	select: (viewer: IRenworksViewer, match_cb: (obj: IRenoworksSpec) => boolean) => void
	selection: (viewer: IRenworksViewer) => IRenoworksSpec[]
	spin: (viewer: IRenworksViewer, degPerFrame: number) => void
	stop: (viewer: IRenworksViewer) => void // Stop Spinning
	objects: (viewer: IRenworksViewer) => any,
	edgeSum: (selection: IRenoworksSpec[]) => {
		eave: number,
		hip: number,
		flash: number,
		stepFlash: number,
		valley: number,
		rake: number,
		ridge: number,
	},
}

type EntityKeys = 'id' | 'createdAt' | 'updatedAt';

type SharedJobKeys = 'title' | 'description' | 'date' | 'selections' | 'pictures' | 'thumbnailSlug' | 'iconSlug' | 'propertyMeasurements' | 'completedOn' | 'dummyProperty';

export type SharedJob = Pick<IJob, EntityKeys | SharedJobKeys>

export type Job = Pick<IJob, EntityKeys | SharedJobKeys | 'hiredOn' | 'bids' | 'hiredBid'>

type ContractorJobKeys = 'bidCount' | 'myBid' | 'property' | 'hasSelectedBid' | 'isSuggested' | 'hasUnreadMessages' | 'trades';
export type ContractorJob = Pick<IContractorJob, 'id' | 'createdAt' | SharedJobKeys | ContractorJobKeys>

export interface SharedProperty extends Entity {
	ready: PropertyStatus
	zipCode: string
	state: string
	city: string
	renoworksId?: string
	legacyHomeSpecId?: string
	owner?: { firstName?: string }
	newViewer: boolean
}

export interface Property extends SharedProperty {
	streetName: string
	houseNumber: string
	legacyHomeSpecId?: string
	renoworksId?: string
	renoworksDisplayId?: string

	jobs?: Job[]
	propertySpec: PropertySpec[]
}

export interface PaymentMethod extends Entity {
	redactedCard: string
	expMonth: number
	expYear: number
	fullName: string
	addressLine1: string
	addressLine2: string
	city: string
	state: string
	zipCode: string
	isDefault: boolean

	stripeSourceId?: string
	stripePaymentMethodId?: string
}

export interface Notification extends Entity {
	title: string;
	//createdAt: string
	message: string
	read: boolean
	link: string
}

export interface User extends Entity {
	firstName: string
	email: string
	createdAt: Date
	role: UserRole
	billingStatus?: "CURRENT" | "PAST_DUE"
	billedMonthly: boolean
	notifications: Notification[]
	notificationCount?: number
	paymentMethods?: PaymentMethod[]
	charges?: Entity[]
	ongoingJobCount?: number
	biddableJobCount?: number
	receiveEmailNotifications?: boolean
}

export interface Picture extends Entity {
	url: string
}
export interface Selection extends Entity {
	question: string
	answer: string
}

export type SpecReferenceValues = SpecReference

export interface PropertyMeasurements {
	format: "v1",
	ref: SpecReferenceValues
	selections: {
		name: string
		material: string
		tags?: string[]
		details: PropertyMeasurementDetail[]
	}[]
	totals: PropertyMeasurementDetail[]
}
interface PropertyMeasurementDetail {
	key?: string
	desc: string
	display: string
	unit?: string | null
}

export type CreateArgs<TEntity = any> = TEntity & {
	id?: never
	createdAt?: never
	updatedAt?: never
}

export type UpdateArgs<TEntity = any> = TEntity & {
	createdAt?: never
	updatedAt?: never
}

export type MutOptions<TData = {}, TArgs = {}> = MutationHookOptions<TData, TArgs>
export type QueryOptions<TArgs = {}> = QueryHookOptions<TArgs>
