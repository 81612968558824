import * as React from 'react'

import JobSidebar from '../components/jobSidebar'
import SEO from '../components/seo/seo'
import HomeInspect from '../components/homeowner/HomeInspect'

const IndexPage = () => (
	<JobSidebar>
		<SEO title="Active jobs" keywords={['3d', 'construction']} />
		<HomeInspect />
	</JobSidebar>
)

export default IndexPage
