import * as React from 'react'
import styled from 'styled-components'
import { useGetUserProperties } from '../../apollo/queries/getUserProperties'
import { useQuery } from '@apollo/react-hooks'
import { GET_SELECTED_PROPERTY_ID } from '../../apollo/queries'
import { PropertyStatus } from '../../apollo/types'
import HouseDenied from '../activeJobs/HouseDenied'
import Loading from '../ui/Loading'
import DummyPropertyWarning from '../DummyPropertyWarning'

export const getExampleRenoworksDisplayId = () => {
	return process.env.GATSBY_EXAMPLE_RENOWORKS_DISPLAY_ID || "52727e2e-0a58-487c-9bde-91aa586e1d98"; // TODO: Remove hardcoded ID
};

const HomeInspect = () => {
	const {
		data: { selectedPropertyId },
	} = useQuery(GET_SELECTED_PROPERTY_ID)
	const { data, loading } = useGetUserProperties({
		fetchPolicy: 'network-only',
	})
	const homeowner = data?.homeowner;

	if (loading) return <Loading />

	const properties = (homeowner && homeowner.properties) || [];

	const property = properties.find(p => p.id === selectedPropertyId);


	if (property.ready === PropertyStatus.Denied || property.ready === PropertyStatus.CANCELED) {
		return <HouseDenied />
	} else if (property.ready === PropertyStatus.PAYMENT_FAILED) {
		return <HouseDenied />
	}

	const renoworksDisplayId = property.ready === PropertyStatus.Initial || property.ready === PropertyStatus.IN_PROGRESS ? getExampleRenoworksDisplayId() : property.renoworksDisplayId;

	return (
		<Container>
			{property.ready !== PropertyStatus.Ready && <DummyPropertyWarning/>}
			<iframe
				style={{ width: "100%", height: "100%", marginBottom: 0 }}
				src={`https://fairmarket.renoworks.com/pages/project?project=${renoworksDisplayId}`}
			/>
		</Container>
	)
}

export default HomeInspect

const Container = styled.div`
	height: 100%;
	width: 100%;
	position: relative;
`
