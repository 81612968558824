import React from 'react'
import styled from 'styled-components'
import { Button, List } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import { Link } from 'gatsby'
import DemoTooltip from '../../components/ui/DemoTooltip'
import SidebarItem from '../ui/sidebar/SidebarItem'

const HomeownerSidebarRoutes = ({ demoMode }: { demoMode?: boolean }) => {
	return (
		<JobsContainer>
			<List>
				<SidebarItem
					url="/jobs"
					name={demoMode ? 'Sample Home' : 'My Jobs'}
					iconName="build"
					partiallyActive={false}
				/>
				<SidebarItem url="/my-home" name="Design Center" iconName="palette" />
				<SidebarItem
					url="https://helpcenter.fairmarkethome.com"
					name="Help Center"
					iconName="help"
				/>
				<SidebarItem
					url="/hoa"
					name={'HOA opportunities'}
					iconName="HOA opportunities"
					partiallyActive={false}
				/>
			</List>
			<DemoTooltip enabled={demoMode}>
				<NewJobLink demoMode={demoMode} to="/jobs/new">
					<NewJobButton>
						<AddIcon style={{ marginRight: 8 }} />
						Create New Job
					</NewJobButton>
				</NewJobLink>
			</DemoTooltip>
		</JobsContainer>
	)
}
export default HomeownerSidebarRoutes

const NewJobButton = styled(Button).attrs({
	variant: 'outlined',
	size: 'small'
})`
	&& {
		margin-top: 8px;
		text-transform: none;
	}
`

const NewJobLink = styled(Link).attrs({
	partiallyActive: true,
	activeClassName: 'active'
})<{ demoMode?: boolean }>`
	${({ demoMode }) =>
		demoMode &&
		`
		&& {
			> * {
				cursor: default;
			}
			&:active {
				pointer-events: none!important;
			}
			opacity: .7;
		}
	`}
	display: flex;
	align-items: stretch;
	flex-direction: column;
	text-decoration: none;
	margin: 8px 20px;
	&.active > * {
		background-color: rgba(255, 255, 255, 0.12);
	}
`

const JobsContainer = styled.div`
	padding: 22px 10px;
	display: flex;
	flex-direction: column;
	flex: 1;
	overflow-y: auto;
	overflow-x: hidden;
`
