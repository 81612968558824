import React from 'react'
import '../ui/sidebar/layout.css'
import StaticDrawerWrapper from '../ui/sidebar/staticDrawerWrapper'

import HomeSelector from './HomeSelector'
import HomeownerSidebarRoutes from './HomeownerSidebarRoutes'
import { usePropertiesIdListQuery } from '../../apollo/generated'
import Loading from '../ui/Loading'

const JobSidebar = ({ children }: { children: React.ReactNode }) => {
	const { data, loading } = usePropertiesIdListQuery()

	if (loading)
		return (
			<StaticDrawerWrapper sideArea={() => <Loading />}>
				{children}
			</StaticDrawerWrapper>
		)

	const {
		homeowner: { properties }
	} = data

	return (
		<>
			<StaticDrawerWrapper
				sideArea={({ closeDrawer }) => (
					<>
						<HomeSelector
							demoMode={properties.length === 0}
							closeDrawer={closeDrawer}
						/>
						<HomeownerSidebarRoutes demoMode={properties.length === 0} />
					</>
				)}
			>
				{children}
			</StaticDrawerWrapper>
		</>
	)
}

export default JobSidebar
