import React from 'react'
import { Tooltip } from '@material-ui/core'
import { TooltipProps } from '@material-ui/core/Tooltip'

interface Props extends Partial<TooltipProps> {
  enabled?: boolean
}

const DemoTooltip = ({ enabled, children, ...tooltipProps }: Props) => (
  enabled ? (
    <Tooltip
      disableTouchListener
      placement="right"
      title="Feature becomes available when you add your first home"
      {...tooltipProps}
    >
      {children}
    </Tooltip>
  ) : (
      <>{children}</>
    )
)

export default DemoTooltip